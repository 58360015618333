export default [
    //commons
    {
        path: '/page/termsConditions',
        name: 'termsConditions',
        component: () => import(/* webpackChunkName: "termsConditions" */ '@/view/pages/TermsConditions.vue'),
    },
    {
        path: '/page/comingSoon',
        name: 'comingSoon',
        component: () => import(/* webpackChunkName: "comingSoon" */ '@/view/pages/ComingSoon.vue'),
    },
    {
        path: '/:catchAll(.*)',
        component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
    },
    //
    {
        path: '/ins-home-banner',
        name: 'ins-home-banner',
        component: () => import('@/view/pages/InspireHomeBanner'),
    },
    {
        path: '/ins-referral',
        name: 'ins-referral',
        component: () => import('@/view/pages/InspireReferral'),
    },
    //
    {
        path: '/ins-about-us',
        name: 'ins-about-us',
        component: () => import('@/view/pages/InspireAboutUs'),
    },
    {
        path: '/ins-our-story',
        name: 'ins-our-story',
        component: () => import('@/view/pages/InspireOurStory'),
    },
    {
        path: '/ins-faq',
        name: 'ins-faq',
        component: () => import('@/view/pages/InspireFAQ'),
    },
    {
        path: '/ins-testimonial',
        name: 'ins-testimonial',
        component: () => import('@/view/pages/InspireTestimonial'),
    },
    {
        path: '/ins-team',
        name: 'ins-team',
        component: () => import('@/view/pages/InspireTeam'),
    },
    //
    {
        path: '/ins-news-blog',
        name: 'ins-news-blog',
        component: () => import('@/view/pages/InspireNewsBlog'),
    },
    {
        path: '/ins-job-list',
        name: 'ins-job-list',
        component: () => import('@/view/pages/InspireJobList'),
    },
    {
        path: '/ins-job-application',
        name: 'ins-job-application',
        component: () => import('@/view/pages/InspireJobApplications'),
    },

    {
        path: '/ins-contact-phone',
        name: 'ins-contact-phone',
        component: () => import('@/view/pages/InspireContactPhone'),
    },
    {
        path: '/ins-social-media',
        name: 'ins-social-media',
        component: () => import('@/view/pages/InspireSocialMedia'),
    },
    {
        path: '/ins-contact-message',
        name: 'ins-contact-message',
        component: () => import('@/view/pages/InspireContactMessage'),
    },
    //
    {
        path: '/ins-internet-package',
        name: 'ins-internet-package',
        component: () => import('@/view/pages/InspireInternetPackage'),
    },
    {
        path: '/ins-package-banner',
        name: 'ins-package-banner',
        component: () => import('@/view/pages/InspirePackageBanner'),
    },
    {
        path: '/ins-package-image',
        name: 'ins-package-image',
        component: () => import('@/view/pages/InspirePackageImage'),
    },
    {
        path: '/ins-package-feature',
        name: 'ins-package-feature',
        component: () => import('@/view/pages/InspirePackageFeatures'),
    },
    {
        path: '/ins-package-about',
        name: 'ins-package-about',
        component: () => import('@/view/pages/InspirePackageAbout'),
    },
    {
        path: '/ins-discover-facility',
        name: 'ins-discover-facility',
        component: () => import('@/view/pages/InspireDiscover'),
    },
    {
        path: '/ins-discover-offer',
        name: 'ins-discover-offer',
        component: () => import('@/view/pages/InspireOffer'),
    },
    //
    {
        path: '/ins-user-list',
        name: 'ins-user-list',
        component: () => import('@/view/pages/InspireUserList'),
    },
    {
        path: '/ins-admin-list',
        name: 'ins-admin-list',
        component: () => import('@/view/pages/InspireAdminList'),
    },
    //
    {
        path: '/ins-shop-product-category',
        name: 'ins-shop-product-category',
        component: () => import('@/view/pages/InspireShopProductCategory'),
    },
    {
        path: '/ins-shop-product',
        name: 'ins-shop-product',
        component: () => import('@/view/pages/InspireShopProduct'),
    },
    {
        path: '/ins-shop-order',
        name: 'ins-shop-order',
        component: () => import('@/view/pages/InspireShopOrder'),
    },    {
        path: '/ins-useful-links',
        name: 'ins-useful-links',
        component: () => import('@/view/pages/InspireUsefulLinks'),
    },    {
        path: '/ins-quick-links',
        name: 'ins-quick-links',
        component: () => import('@/view/pages/InspireQuickLinks'),
    },
    {
        path: '/ins-shop-payment-history',
        name: 'ins-shop-payment-history',
        component: () => import('@/view/pages/InspireShopPaymentHistory.vue'),
    }
    //

    // ///region ----Not Applicable for this project----
    // {
    //     path: '/my-posts',
    //     name: 'my-posts',
    //     component: () => import(/* webpackChunkName: "My Post" */ '@/view/pages/MyPosts.vue'),
    // },
    // {
    //     path: '/my-users',
    //     name: 'my-users',
    //     component: () => import(/* webpackChunkName: "My Users" */ '@/view/pages/MyUsers.vue'),
    // },
    // {
    //     path: '/my-basic',
    //     name: 'my-basic',
    //     component: () => import(/* webpackChunkName: "My Users Basic" */ '@/view/pages/MyUsersBasic.vue'),
    // },
    // {
    //     path: '/my-gallery',
    //     name: 'my-gallery',
    //     component: () => import(/* webpackChunkName: "My Gallery" */ '@/view/pages/MyGallery.vue'),
    // },
    // {
    //     path: '/starter',
    //     name: 'starter',
    //     component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/BlankPage.vue'),
    // },
    // {
    //     path: '/page/testimonials',
    //     name: 'testimonials',
    //     component: () => import(/* webpackChunkName: "Testimonials" */ '@/view/pages/Testimonials.vue'),
    // },
    // {
    //     path: '/page/faqs',
    //     name: 'faq`s',
    //     component: () => import(/* webpackChunkName: "Faq" */ '@/view/pages/Faq.vue'),
    // },
    // {
    //     path: '/page/search',
    //     name: 'search',
    //     component: () => import(/* webpackChunkName: "Search" */ '@/view/pages/Search.vue'),
    // },
    // {
    //     path: '/page/gallery',
    //     name: 'gallery',
    //     component: () => import(/* webpackChunkName: "Gallery" */ '@/view/pages/Gallery.vue'),
    // },
    // {
    //     path: '/page/pricing',
    //     name: 'pricing',
    //     component: () => import(/* webpackChunkName: "PricingTable" */ '@/view/pages/PricingTable.vue'),
    // },
    // {
    //     path: '/page/banners',
    //     name: 'banners',
    //     component: () => import(/* webpackChunkName: "Banners" */ '@/view/pages/Banners.vue'),
    // },
    // {
    //     path: '/page/support',
    //     name: 'support',
    //     component: () => import(/* webpackChunkName: "support" */ '@/view/pages/Support.vue'),
    // },
    // {
    //     path: '/page/maintenance',
    //     name: 'maintenance',
    //     component: () => import(/* webpackChunkName: "maintenance" */ '@/view/pages/Maintenance.vue'),
    // },
    // {
    //     path: '/page/404',
    //     name: '404',
    //     component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
    // },
    // {
    //     path: '/page',
    //     name: 'settings',
    //     component: () => import(/* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'),
    //     children: [
    //         {
    //             path: 'profile-settings',
    //             name: 'profile-settings',
    //             component: () => import(/* webpackChunkName: "Profile" */ '@/view/pages/settings/overview/Profile.vue'),
    //         },
    //         {
    //             path: 'profile-settings/profile',
    //             name: 'set-profile',
    //             component: () => import(/* webpackChunkName: "Profile" */ '@/view/pages/settings/overview/Profile.vue'),
    //         },
    //         {
    //             path: 'profile-settings/account',
    //             name: 'set-account',
    //             component: () => import(/* webpackChunkName: "Account" */ '@/view/pages/settings/overview/Account.vue'),
    //         },
    //         {
    //             path: 'profile-settings/password',
    //             name: 'set-password',
    //             component: () => import(/* webpackChunkName: "Password" */ '@/view/pages/settings/overview/Password.vue'),
    //         },
    //         {
    //             path: 'profile-settings/social',
    //             name: 'set-social',
    //             component: () => import(/* webpackChunkName: "Social" */ '@/view/pages/settings/overview/SocialProfile.vue'),
    //         },
    //         {
    //             path: 'profile-settings/notification',
    //             name: 'set-notification',
    //             component: () =>
    //                 import(/* webpackChunkName: "Notification" */ '@/view/pages/settings/overview/Notification.vue'),
    //         },
    //     ],
    // },
    // {
    //     path: '/page/knowledgebase',
    //     name: 'knowledgebase',
    //     component: () => import(/* webpackChunkName: "knowledgebase" */ '@/view/pages/knowledgebase/MainBox.vue'),
    //     children: [
    //         {
    //             path: 'base',
    //             name: 'base',
    //             component: () => import(/* webpackChunkName: "base" */ '@/view/pages/knowledgebase/Index.vue'),
    //             children: [
    //                 {
    //                     path: 'plugins',
    //                     name: 'kno-plugins',
    //                     component: () =>
    //                         import(/* webpackChunkName: "Plugins" */ '@/view/pages/knowledgebase/overview/ArticlePlugin.vue'),
    //                 },
    //                 {
    //                     path: 'themes',
    //                     name: 'kno-themes',
    //                     component: () =>
    //                         import(/* webpackChunkName: "Themes" */ '@/view/pages/knowledgebase/overview/ArticleTheme.vue'),
    //                 },
    //                 {
    //                     path: 'extensions',
    //                     name: 'kno-extensions',
    //                     component: () =>
    //                         import(/* webpackChunkName: "Extensions" */ '@/view/pages/knowledgebase/overview/ArticleExtension.vue'),
    //                 },
    //             ],
    //         },
    //         {
    //             path: 'all-articles',
    //             name: 'all-articles',
    //             component: () => import(/* webpackChunkName: "all-articles" */ '@/view/pages/knowledgebase/AllArticle.vue'),
    //         },
    //         {
    //             path: 'single/1',
    //             name: 'knowledge-base-singale',
    //             component: () =>
    //                 import(/* webpackChunkName: "knowledge-base" */ '@/view/pages/knowledgebase/SingleKnowledge.vue'),
    //         },
    //     ],
    // },
    // {
    //     path: '/changelog',
    //     name: 'changelog',
    //     component: () => import(/* webpackChunkName: "changelog" */ '@/view/pages/Changelog.vue'),
    // },
    // {
    //     path: '/page/blog',
    //     name: 'blog',
    //     component: () => import(/* webpackChunkName: "blog" */ '@/view/pages/blog/Index.vue'),
    //     children: [
    //         {
    //             path: 'blog1',
    //             name: 'blog1',
    //             component: () => import(/* webpackChunkName: "blog1" */ '@/view/pages/blog/BlogOne.vue'),
    //         },
    //         {
    //             path: 'blog2',
    //             name: 'blog2',
    //             component: () => import(/* webpackChunkName: "blog2" */ '@/view/pages/blog/BlogTwo.vue'),
    //         },
    //         {
    //             path: 'blog3',
    //             name: 'blog3',
    //             component: () => import(/* webpackChunkName: "blog3" */ '@/view/pages/blog/BlogThree.vue'),
    //         },
    //         {
    //             path: 'details/:id',
    //             name: 'details',
    //             component: () => import(/* webpackChunkName: "details" */ '@/view/pages/blog/BlogDetails.vue'),
    //         }
    //     ],
    // },
    // ///endregion
];
